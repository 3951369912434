.plane {
  // @include absolute;
  position: absolute;
  top: 0;
  bottom: 0;
  // height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  pointer-events: none;
  display: block;
  // opacity: 0;

  // transform-origin: 0% 0%;
  // transform: scale(0, 0);
}
