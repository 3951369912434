.index {
  &__section {
    &--success {
      position: relative;
    }
  }

  &__services {
    padding: 8.2rem 0 3rem;
    @include min769 {
    padding: 18.2rem 0 1rem;

    }
  }

  &__success {
    position: relative;
  }
}
