.hero {
  &__container {
    padding: 0 2rem;
    position: relative;

    @include min769 {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      padding: 2rem;
    }
  }

  &__title {
    display: block;
    font-size: 7.2rem;
    position: relative;
    z-index: 2;
    line-height: 0.94;

    @include min769 {
      line-height: 0.95;
      font-size: 14.4rem;
      width: 59.8%;
      margin-top: 6.2rem;
    }
  }

  &__content {
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 1.3rem 0 0rem 16.4%;

    @include min769 {
      will-change: transform;
      padding: 3.9rem 0 0rem;
      width: 34.5%;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    line-height: 1.5;
    font-size: 1.2rem;
    width: 100%;
    color: #a6a6a6;

    @include min769 {
      line-height: 1.67;
      font-size: 1.8rem;
    }

    p {
      margin: 1.8em 0;
    }

    &--blue {
      position: relative;
      z-index: 2;
      font-size: 4.8rem;
      color: var(--primaryColor);
      font-weight: 700;
      line-height: 1;

      @include min769 {
        font-size: 7.2rem;
      }

      p {
        margin: 0;
      }
    }
  }

  &__plane {
    position: absolute;
    z-index: 1;
    bottom: -4.4rem;
    top: -14.7rem;
    left: 7.3%;
    width: 98%;

    @include min769 {
      bottom: -10rem;
      top: -3.7rem;
      left: -74.7%;
      width: 191%;
    }
  }

  &__scrollto {
    position: absolute;
    bottom: -5rem;
    left: calc(50% - 1.1rem);
    cursor: pointer;
    width: 2.3rem;
    height: 5.1rem;
    z-index: 3;
    // transition: opacity 500ms var(--e);
    display: none;
    opacity: 0.5;

    @include hover {
      opacity: 0.7;
    }

    @include min769 {
      display: block;
    }

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
