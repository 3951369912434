.outliner {
  font-weight: 700;
  display: block;
  position: relative;

  &__fill {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 120%;
    overflow: hidden;
    z-index: 2;
    color: inherit;
    -webkit-text-fill-color: currentColor;
    -webkit-text-stroke: 1px currentColor;
  }

  &__line {
    display: block;
    position: relative;
    color: #fff;
    -webkit-text-stroke: 1px currentColor;
    &--blue {
      color: var(--primaryColor);
      .outliner {
        &__fill {
          color: var(--primaryColor);
        }
      }
    }
    ._before-outline & {
      -webkit-text-stroke: 1px currentColor;
      -webkit-text-fill-color: transparent;
      &--blue {
        color: #fff;
      }
      .outliner {
        &__fill {
          will-change: height;
        }
      }
    }
  }
}
