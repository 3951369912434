.service {
  &__plane {
    right: 0;
    width: 100%;
    &--index {
      right: auto;
      left: 0;
    }
    &--sections-2 {
      right: auto;
      left: 0;
    }
  }

  &__visual {
    width: 11.8rem;
    height: 11.8rem;
    position: relative;

    &--sections {
      @include min769 {
        position: absolute;
        top: 9rem;
        width: 26.4rem;
        height: 26.4rem;
      }
    }

    &--sections-1 {
      @include min769 {
        left: 8.5%;
      }
    }

    &--sections-2 {
      @include min769 {
        right: 8.5%;
      }
    }
  }

  &__media {
    position: absolute;
    top: 2rem;
    width: 8rem;
    height: 8rem;

    &--right {
      right: 0;
      transform: translateX(32%);
    }

    &--left {
      transform: translateX(-32%);
      left: 0;
    }

    &--sections {
      @include min769 {
        top: 4.4rem;
        width: 18rem;
        height: 18rem;
      }
    }
  }

  &__icon {
    width: 7.5rem;
    height: 7.5rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 2.3rem;
      height: 1px;
      background-color: #fff;
      top: 3.7rem;
      left: 2.6rem;
    }

    &:after {
      transform: rotate(-90deg);
    }

    &--tl {
      top: -3.7rem;
      left: -3.7rem;
    }

    &--tr {
      top: -3.7rem;
      right: -3.7rem;
    }

    &--bl {
      bottom: -3.7rem;
      left: -3.7rem;
    }

    &--br {
      bottom: -3.7rem;
      right: -3.7rem;
    }

    &--sections {
      @include min769 {
        transform: scale(2.27);
      }
    }
  }

  &__content {
    &--sections {
      @include min769 {
        padding: 6.4rem 0;
      }
    }
  }

  &__subtitle {
    font-size: 1.8rem;
    text-transform: uppercase;
    padding-top: 2.8rem;

    &--sections {
      @include min769 {
        font-size: 4rem;
      }
    }
  }

  &__intro {
    color: #807e80;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.71;
    padding: 1.2rem 0 0.2rem;

    &--sections {
      @include min769 {
        padding: 0.1rem 0 0.9rem 0;
        font-size: 3rem;
        line-height: 1.67;
      }
    }

    &--index {
      @include min769 {
        max-width: 30rem;
      }
    }
  }

  &__description {
    color: #807e80;
    &--sections {
      margin-left: -5.4rem;

      @include min769 {
        margin-left: 0;
      }
    }
  }
}
