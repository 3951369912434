.services {
  &__top {
    padding: 0 0 1rem 2rem;
    position: relative;

    &--index {
      text-align: right;
      @include min769 {
      }
    }
  }

  &__title {
    color: #000;
    font-size: 3.6rem;
    line-height: 0.9;
    position: relative;
    @include min769 {
      font-size: 6rem;
    }

    &--sections {
      @include min769 {
        line-height: 0.83;
        font-size: 14.4rem;
      }
    }
  }

  &__sections {
    padding: 3rem 0;

    @include min769 {
      padding: 9rem 0;
    }
  }

  &__section {
    padding: 4.8rem 0;

    @include min769 {
      padding: 0;
    }
  }

  &__blocks {
    padding: 1rem 0;
    @include min769 {
      padding: 8.8rem 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__block {
    padding: 4rem 0rem 4rem 5.4rem;

    @include min769 {
      padding: 0;

      width: 33.33%;
      position: relative;

      &--1 {
        top: -16rem;
        padding-top: 16rem;
        margin-bottom: -16rem;
      }

      &--2 {
        top: -8rem;
        padding-top: 8rem;
        margin-bottom: -8rem;
      }
    }
  }

  &__service {
    position: relative;

    &--sections {
      padding-left: 5.4rem;
    }

    &--sections-1 {
      @include min769 {
        padding: 0 0 0 42.6%;
      }
    }

    &--sections-2 {
      @include min769 {
        padding: 0 42.6% 0 0;
      }
    }
  }
}
