:root {
  --primaryColor: #3176b4;
  --primaryActive: #0007c5;
  --primaryFont: "Roboto Mono", Arial, sans-serif;
  --red: #ff0000;
  --greyLight: #a6a6a6;
  --grey: #807e80;
  --greyDark: #2a2c31;
}

:root {
  --easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
  --e: var(--easeOutQuad);
}

@font-face {
  font-family: "Roboto Mono";
  font-display: fallback;
  src: url(../fonts/RobotoMono-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Roboto Mono";
  font-display: fallback;
  src: url(../fonts/RobotoMono-Italic.woff) format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Roboto Mono";
  font-display: fallback;
  src: url(../fonts/RobotoMono-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
}
button,
input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  font-weight: inherit;
  line-height: inherit;
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  background-color: transparent;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

svg:not(:root) {
  overflow: visible;
}

select {
  display: inline-block;
  width: 100%;
}

option {
  width: 100%;
  display: block;
}

textarea {
  resize: none;
}

main,
article,
section,
header {
  display: block;
}

img {
  max-width: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  box-shadow: none;
  color: inherit;
  outline: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-feature-settings: "liga", "clig off";
          font-feature-settings: "liga", "clig off";
  -webkit-font-variant-ligatures: no-common-ligatures;
          font-variant-ligatures: no-common-ligatures;
  text-rendering: optimizeLegibility;
}

html {
  background-color: #2a2c31;
  background-color: var(--greyDark);
  color: #fff;
  font-size: 10px;
}

body {
  font-family: "Roboto Mono", Arial, sans-serif;
  font-family: var(--primaryFont);
  color: inherit;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;
}
body ::-webkit-selection {
  background-color: #2a2c31;
  background-color: var(--greyDark);
  color: #fff;
}
body :-moz-selection {
  background-color: #2a2c31;
  background-color: var(--greyDark);
  color: #fff;
}
body ::-moz-selection {
  background-color: #2a2c31;
  background-color: var(--greyDark);
  color: #fff;
}
body ::selection {
  background-color: #2a2c31;
  background-color: var(--greyDark);
  color: #fff;
}
body ::-webkit-input-placeholder {
  opacity: 1;
  transition: all 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 350ms var(--e);
}
body ::-moz-placeholder {
  opacity: 1;
  transition: all 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 350ms var(--e);
}
body :-ms-input-placeholder {
  opacity: 1;
  transition: all 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 350ms var(--e);
}
body ::-ms-input-placeholder {
  opacity: 1;
  transition: all 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 350ms var(--e);
}
body ::placeholder {
  opacity: 1;
  transition: all 350ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: all 350ms var(--e);
}

:focus::-webkit-input-placeholder {
  opacity: 0.3;
}

:focus::-moz-placeholder {
  opacity: 0.3;
}

:focus:-ms-input-placeholder {
  opacity: 0.3;
}

:focus::-ms-input-placeholder {
  opacity: 0.3;
}

:focus::placeholder {
  opacity: 0.3;
}

a {
  text-decoration: none;
}

table {
  max-width: 100%;
}
table a {
  text-decoration: underline;
}

tr {
  vertical-align: top;
}

td {
  text-align: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
}

html {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1;
  font-weight: 700;
  font-family: "Roboto Mono", Arial, Helvetica, sans-serif;
}

h1,
h2 {
  font-weight: 700;
  font-size: inherit;
}

p {
  margin: 1.8em 0;
}
p img {
  width: 100%;
}

.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  line-height: 1.14;
  min-height: 6rem;
  padding: 1rem 1.6rem;
  border: solid 0.3rem #151515;
  text-align: center;
  transition: background-color 100ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: background-color 100ms var(--e);
}
.btn path,
.btn polygon {
  transition: fill 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: fill 200ms var(--e);
  position: relative;
}
.btn__text {
  position: relative;
  color: currentColor;
  margin: 0 1rem;
}
.btn__icon {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
  width: 2rem;
  margin: 0 1rem;
  text-align: center;
  color: currentColor;
}
.btn__icon svg {
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
}
.btn__icon path {
  fill: currentColor;
}
.btn--download {
  justify-content: space-between;
}

.container {
  max-width: 118rem;
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
}

.media {
  display: block;
}
.media__image {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.outliner {
  font-weight: 700;
  display: block;
  position: relative;
}
.outliner__fill {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 120%;
  overflow: hidden;
  z-index: 2;
  color: inherit;
  -webkit-text-fill-color: currentColor;
  -webkit-text-stroke: 1px currentColor;
}
.outliner__line {
  display: block;
  position: relative;
  color: #fff;
  -webkit-text-stroke: 1px currentColor;
}
.outliner__line--blue {
  color: #3176b4;
  color: var(--primaryColor);
}
.outliner__line--blue .outliner__fill {
  color: #3176b4;
  color: var(--primaryColor);
}
._before-outline .outliner__line {
  -webkit-text-stroke: 1px currentColor;
  -webkit-text-fill-color: transparent;
}
._before-outline .outliner__line--blue {
  color: #fff;
}
._before-outline .outliner__line .outliner__fill {
  will-change: height;
}

.plane {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  pointer-events: none;
  display: block;
}

.title {
  color: #000;
  line-height: 1;
  font-weight: 700;
  position: relative;
  display: block;
}
.title__line {
  display: block;
}
.title__line--1:before {
  line-height: inherit;
  content: "_";
  color: #3176b4;
  color: var(--primaryColor);
}
.title__line--index:before {
  display: none;
}
.title__fill {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  bottom: auto;
  overflow: hidden;
  height: 90%;
}
.title__letter {
  display: inline-block;
}

.hero__container {
  padding: 0 2rem;
  position: relative;
}
.hero__title {
  display: block;
  font-size: 7.2rem;
  position: relative;
  z-index: 2;
  line-height: 0.94;
}
.hero__content {
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 1.3rem 0 0rem 16.4%;
}
.hero__text {
  position: relative;
  z-index: 2;
  line-height: 1.5;
  font-size: 1.2rem;
  width: 100%;
  color: #a6a6a6;
}
.hero__text p {
  margin: 1.8em 0;
}
.hero__text--blue {
  position: relative;
  z-index: 2;
  font-size: 4.8rem;
  color: #3176b4;
  color: var(--primaryColor);
  font-weight: 700;
  line-height: 1;
}
.hero__text--blue p {
  margin: 0;
}
.hero__plane {
  position: absolute;
  z-index: 1;
  bottom: -4.4rem;
  top: -14.7rem;
  left: 7.3%;
  width: 98%;
}
.hero__scrollto {
  position: absolute;
  bottom: -5rem;
  left: calc(50% - 1.1rem);
  cursor: pointer;
  width: 2.3rem;
  height: 5.1rem;
  z-index: 3;
  display: none;
  opacity: 0.5;
}
.hero__scrollto svg {
  display: block;
  width: 100%;
  height: 100%;
}

.service__plane {
  right: 0;
  width: 100%;
}
.service__plane--index {
  right: auto;
  left: 0;
}
.service__plane--sections-2 {
  right: auto;
  left: 0;
}
.service__visual {
  width: 11.8rem;
  height: 11.8rem;
  position: relative;
}
.service__media {
  position: absolute;
  top: 2rem;
  width: 8rem;
  height: 8rem;
}
.service__media--right {
  right: 0;
  transform: translateX(32%);
}
.service__media--left {
  transform: translateX(-32%);
  left: 0;
}
.service__icon {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
}
.service__icon:before, .service__icon:after {
  content: "";
  position: absolute;
  width: 2.3rem;
  height: 1px;
  background-color: #fff;
  top: 3.7rem;
  left: 2.6rem;
}
.service__icon:after {
  transform: rotate(-90deg);
}
.service__icon--tl {
  top: -3.7rem;
  left: -3.7rem;
}
.service__icon--tr {
  top: -3.7rem;
  right: -3.7rem;
}
.service__icon--bl {
  bottom: -3.7rem;
  left: -3.7rem;
}
.service__icon--br {
  bottom: -3.7rem;
  right: -3.7rem;
}
.service__subtitle {
  font-size: 1.8rem;
  text-transform: uppercase;
  padding-top: 2.8rem;
}
.service__intro {
  color: #807e80;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.71;
  padding: 1.2rem 0 0.2rem;
}
.service__description {
  color: #807e80;
}
.service__description--sections {
  margin-left: -5.4rem;
}

.success {
  padding: 2.4rem 0 0rem;
  margin-bottom: 3rem;
}
.success__container {
  position: relative;
  z-index: 3;
}
.success__title {
  color: #2a2b31;
  line-height: 1.1;
  font-size: 3.6rem;
  z-index: 2;
}
.success__items {
  padding: 3.5rem 0 1rem;
}
.success__item {
  padding: 2.2rem 0 2.2rem 5.3rem;
  position: relative;
}
.success__subtitle {
  line-height: 1.333;
  font-weight: 700;
  font-size: 1.8rem;
  padding-right: 7rem;
}
.success__description {
  color: #807e80;
  font-size: 1.4rem;
  line-height: 1.71;
}
.success__description p {
  margin: 1.4em 0;
}
.success__plane {
  z-index: 1;
  right: 12.2%;
  width: 87.8%;
}

.cookiebar {
  bottom: 3rem;
  right: 3rem;
  left: 3rem;
  background-color: #000;
  padding: 0.2rem 2.5rem 0.7rem;
  font-size: 1.4rem;
  color: #807e80;
}
.cookiebar p {
  margin: 1em 0;
}
.cookiebar a {
  color: #fff;
}
.cookiebar__close {
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: -2.6rem;
  right: -2.6rem;
  cursor: pointer;
}
.cookiebar__close:before, .cookiebar__close:after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 1px;
  background-color: #fff;
  top: 2.6rem;
  left: 1.8rem;
}
.cookiebar__close:before {
  transform: rotate(45deg);
}
.cookiebar__close:after {
  transform: rotate(-45deg);
}

.footer__content {
  position: relative;
}
.footer__contacts {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.25;
  margin: 1.3rem 0;
}
.footer__contact {
  display: inline-block;
}
.footer__title {
  font-size: 3.6rem;
  font-weight: 700;
}
.footer__bottom {
  padding: 5.8rem 0 1rem;
}
.footer__copyright {
  text-align: right;
  font-size: 1rem;
  display: block;
  color: #fff;
  transition: opacity 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: opacity 0.5s var(--e);
  opacity: 0.5;
}
.nav__trigger {
  padding: 1.7rem;
  font-size: 1.8rem;
  display: block;
  color: currentColor;
}
.nav__item {
  color: #a6a6a6;
  color: var(--greyLight);
  transition: color 200ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
  transition: color 200ms var(--e);
}
.nav__item.is-active {
  color: #fff;
}

.navicon {
  width: 5rem;
  height: 7rem;
  cursor: pointer;
}
.navicon__dot {
  position: absolute;
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  top: 3.5rem;
  right: 2.2rem;
}
.navicon__dot--1 {
  transform: translate3d(0, -0.9rem, 0);
}
.navicon__dot--3 {
  transform: translate3d(0, 0.9rem, 0);
}

.protected {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  background-color: #000;
  text-align: center;
}
.protected__icon {
  display: inline-block;
  width: 7.1rem;
  height: 7.1rem;
}
.protected__icon svg {
  display: block;
  width: 100%;
  height: 100%;
}
.protected__intro {
  font-size: 1.2rem;
}
.protected__intro p {
  margin: 1.1em 0;
}
.protected__form {
  max-width: 36.6rem;
  display: block;
  margin: 4rem auto;
}
.protected__input {
  display: block;
  width: 100%;
  font-size: 1.4rem;
  font-weight: 700;
  color: #fff;
  padding: 1.7rem 2.1rem;
  border: solid 1px #807e80;
  background-color: rgba(255, 255, 255, 0.05);
}
.protected__input:focus {
  border-color: #fff;
}
.protected__error {
  text-align: right;
  color: #ff5050;
  font-size: 1.2rem;
  padding: 0.4rem 0;
  opacity: 0;
}
.has-error .protected__error {
  opacity: 1;
}
.protected__submit {
  margin: 0.9rem 0;
  padding: 0.8rem 1rem 1rem;
  min-height: none;
  font-size: 1.4rem;
  letter-spacing: 0.4em;
  font-weight: 700;
  background-color: #3176b4;
  background-color: var(--primaryColor);
  border-color: #3176b4;
  border-color: var(--primaryColor);
}

/* stylelint-disable */
.app {
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: 0% 0%;
}
.app__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  overflow: hidden;
  z-index: -2;
}
.app__parallax {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: url(../img/background.png);
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: 0% 0%;
  will-change: transform;
  z-index: -2;
}
.app__header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 7.8rem;
}
.app__logo {
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  padding: 2.7rem 1.9rem;
  font-size: 2.4rem;
  font-weight: 700;
  color: #fff;
  z-index: 3;
  pointer-events: all;
}
.app__nav {
  position: fixed;
  top: 0;
  pointer-events: all;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
  background-color: #000;
  padding: 10vh 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  display: none;
}
.app__navicon {
  z-index: 3;
  top: 0;
  right: 0;
  position: fixed;
  pointer-events: all;
}
.app__page {
  position: relative;
  z-index: 1;
  padding: 12.8rem 0 0;
  width: 100%;
  overflow-x: hidden;
}
.app__footer {
  padding: 2.3rem 0;
  text-align: right;
}
.app__observer {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
.app__cookiebar {
  position: fixed;
  z-index: 2;
  display: none;
  pointer-events: all;
}
.has-cookiebar .app__cookiebar {
  display: block;
}

.about__intro {
  color: #fff;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1;
}
.about__content {
  position: relative;
}
.about__title {
  color: #000;
  font-size: 3.6rem;
  text-align: right;
  padding: 5.6rem 0 2.7rem;
}
.about__blocks {
  margin: 0;
}
.about__block {
  margin-bottom: 1.5rem;
}
.about__wrapper {
  position: relative;
  padding: 2.6rem 3rem 2.9rem;
}
.about__subtitle {
  position: relative;
  z-index: 2;
  font-size: 2.4rem;
}
.about__text {
  position: relative;
  z-index: 2;
  color: #807e80;
  font-weight: 400;
  padding: 0.6rem 0;
  font-size: 1.2rem;
  line-height: 1.5;
}
.about__text p {
  margin: 1em 0;
}
.about__plane {
  width: 100%;
}
.about__plane--1 {
  right: 0;
}
.about__plane--2 {
  left: 0;
}

.default {
  padding: 2.3rem 0 1.1rem;
}
.default__title {
  font-size: 3rem;
}
.default__content {
  color: #a6a6a6;
  color: var(--greyLight);
  max-width: 82rem;
  padding: 3.4rem 0;
}
.default__content ul,
.default__content ol {
  padding: 0 0 0 5.6rem;
}
.default__content ul li,
.default__content ol li {
  list-style-type: none;
}
.default__content ul li:after,
.default__content ol li:after {
  content: "";
  position: absolute;
  top: 0.5rem;
  left: -1.8rem;
  width: 0.9rem;
  height: 0.9rem;
  background-color: #3176b4;
  background-color: var(--primaryColor);
}
.default__content ol {
  counter-reset: section;
}
.default__content ol li {
  text-indent: 2.3rem;
  position: relative;
}
.default__content ol li:before {
  counter-increment: section;
  content: counter(section) ".";
  position: absolute;
  top: 0;
  left: -2.2rem;
}

.index__section--success {
  position: relative;
}
.index__services {
  padding: 8.2rem 0 3rem;
}
.index__success {
  position: relative;
}

.factors__title {
  color: #000;
  font-size: 6rem;
  line-height: 0.9;
}
.factors__intro {
  position: relative;
  z-index: 2;
  color: #a6a6a6;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.8;
  padding: 2.5rem 25% 1rem 0;
}
.factors__blocks {
  margin: 5.5rem 0 0rem;
}
.factors__block {
  margin-bottom: 1.7rem;
}
.factors__wrapper {
  position: relative;
  padding: 2rem 3.2rem 3.8rem 3.2rem;
}
.factors__subtitle {
  position: relative;
  z-index: 2;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.55;
  padding-right: 29%;
}
.factors__subtitle:after {
  font-weight: inherit;
  content: "_";
  display: block;
  color: #3176b4;
  color: var(--primaryColor);
}
.factors__text {
  position: relative;
  z-index: 2;
  color: #807e80;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2;
}
.factors__text p {
  margin: 1em 0;
}
.factors__plane {
  left: 0;
  width: 100%;
}

.services__top {
  padding: 0 0 1rem 2rem;
  position: relative;
}
.services__top--index {
  text-align: right;
}
.services__title {
  color: #000;
  font-size: 3.6rem;
  line-height: 0.9;
  position: relative;
}
.services__sections {
  padding: 3rem 0;
}
.services__section {
  padding: 4.8rem 0;
}
.services__blocks {
  padding: 1rem 0;
}
.services__block {
  padding: 4rem 0rem 4rem 5.4rem;
}
.services__service {
  position: relative;
}
.services__service--sections {
  padding-left: 5.4rem;
}

._before-tween {
  opacity: 0;
}

@media only screen and (min-width: 769px) {
  body {
    font-size: 1.8rem;
    line-height: 1.67;
  }
  p {
    margin: 1.8em 0;
  }
  .title__line {
    padding-left: 28.7rem;
  }
  .title__line--1 {
    padding-left: 0;
  }
  .title__line--index {
    padding-left: 0;
  }
  .title__line--success {
    padding-left: 0;
  }
  .hero__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2rem;
  }
  .hero__title {
    line-height: 0.95;
    font-size: 14.4rem;
    width: 59.8%;
    margin-top: 6.2rem;
  }
  .hero__content {
    will-change: transform;
    padding: 3.9rem 0 0rem;
    width: 34.5%;
  }
  .hero__text {
    line-height: 1.67;
    font-size: 1.8rem;
  }
  .hero__text--blue {
    font-size: 7.2rem;
  }
  .hero__plane {
    bottom: -10rem;
    top: -3.7rem;
    left: -74.7%;
    width: 191%;
  }
  .hero__scrollto {
    display: block;
  }
  .service__visual--sections {
    position: absolute;
    top: 9rem;
    width: 26.4rem;
    height: 26.4rem;
  }
  .service__visual--sections-1 {
    left: 8.5%;
  }
  .service__visual--sections-2 {
    right: 8.5%;
  }
  .service__media--sections {
    top: 4.4rem;
    width: 18rem;
    height: 18rem;
  }
  .service__icon--sections {
    transform: scale(2.27);
  }
  .service__content--sections {
    padding: 6.4rem 0;
  }
  .service__subtitle--sections {
    font-size: 4rem;
  }
  .service__intro--sections {
    padding: 0.1rem 0 0.9rem 0;
    font-size: 3rem;
    line-height: 1.67;
  }
  .service__intro--index {
    max-width: 30rem;
  }
  .service__description--sections {
    margin-left: 0;
  }
  .success {
    padding: 0;
    margin-bottom: 6rem;
  }
  .success__title {
    position: absolute;
    line-height: 1.2;
    font-size: 6rem;
    top: 10.1rem;
    left: 0;
  }
  .success__items {
    padding: 6.3rem 0 8.7rem 34.2%;
    display: flex;
    flex-wrap: wrap;
  }
  .success__item {
    width: 50%;
    padding: 3.7rem 2rem 3.7rem 0;
  }
  .success__item--1 {
    top: -6rem;
    padding-top: 9.7rem;
    margin-bottom: -6rem;
  }
  .success__subtitle {
    line-height: 1.5;
    font-size: 2.4rem;
    max-width: 32rem;
    min-height: 7.2rem;
  }
  .success__description {
    line-height: 1.67;
    font-size: 1.8rem;
    max-width: 32rem;
  }
  .success__plane {
    right: 51.2%;
    width: 48.8%;
  }
  .cookiebar {
    left: auto;
    font-size: 1.8rem;
    bottom: 5.3rem;
    right: 5.3rem;
    width: 53.6rem;
    padding: 0.2rem 2.5rem 0.7rem;
  }
  .cookiebar__close {
    width: 7.5rem;
    height: 7.5rem;
    top: -3.7rem;
    right: -3.7rem;
  }
  .cookiebar__close:before, .cookiebar__close:after {
    width: 2.3rem;
    top: 3.7rem;
    left: 2.6rem;
  }
  .footer__contacts {
    margin: 0;
    padding: 0 20% 0 0;
    font-size: 7.2rem;
  }
  .footer__title {
    font-size: 6rem;
    top: 2.2rem;
    right: -0.6rem;
    position: absolute;
  }
  .footer__bottom {
    padding: 7rem 0 1rem;
  }
  .footer__copyright {
    font-size: 1.2rem;
  }
  .nav__list {
    display: flex;
  }
  .nav__trigger {
    font-size: 1.4rem;
    padding: 2.4rem 1.7rem;
  }
  .protected__intro {
    font-size: 2.4rem;
  }
  .protected__intro p {
    margin: 0.64em 0;
  }
  .protected__form {
    margin: 4.2rem auto;
  }
  .protected__input {
    padding: 1.5rem 2.1rem;
  }
  .app__logo {
    padding: 2.7rem 2.5rem;
  }
  .app__nav {
    padding: 0;
    text-align: left;
    overflow: visible;
    background-color: transparent;
    right: 1.4rem;
    left: auto;
    bottom: auto;
    display: block;
  }
  .app__navicon {
    display: none;
  }
  .app__page {
    padding: 16.8rem 0 0;
  }
  .app__footer {
    text-align: left;
    padding: 2.8rem 0;
  }
  .about__intro {
    font-size: 7.2rem;
  }
  .about__title {
    font-size: 6rem;
    position: absolute;
    top: 12.4rem;
    right: 0rem;
    padding: 0;
  }
  .about__blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 4.8rem -1.5rem;
  }
  .about__block {
    margin-bottom: 0;
    padding: 1.5rem;
    width: 50%;
  }
  .about__block--2 {
    margin-top: 28.4rem;
  }
  .about__wrapper {
    padding: 5.4rem 12% 7.1rem;
  }
  .about__subtitle {
    font-size: 3rem;
  }
  .about__text {
    padding: 3.2rem 0;
    font-size: 1.4rem;
    line-height: 1.73;
  }
  .default {
    padding: 1.8rem 0;
  }
  .default__title {
    font-size: 6rem;
  }
  .default__content {
    padding: 5.1rem 2rem;
  }
  .default__content ul,
.default__content ol {
    padding: 0 0 0 11.2rem;
  }
  .default__content ul li:after,
.default__content ol li:after {
    top: 1.1rem;
    left: -2.8rem;
  }
  .default__content ol li {
    text-indent: 3.3rem;
  }
  .default__content ol li:before {
    left: -3.2rem;
  }
  .index__services {
    padding: 18.2rem 0 1rem;
  }
  .factors__title {
    line-height: 0.83;
    font-size: 14.4rem;
  }
  .factors__intro {
    padding: 2.5rem 0 1rem;
  }
  .factors__blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 8.8rem -1.5rem;
  }
  .factors__block {
    margin-bottom: 0;
    padding: 1.5rem;
    width: 25%;
  }
  .factors__block--1, .factors__block--3 {
    margin-top: 19rem;
  }
  .factors__wrapper {
    padding: 2rem 3.2rem 6.3rem 3.2rem;
  }
  .factors__subtitle {
    padding-right: 0;
    font-size: 2.4rem;
  }
  .services__title {
    font-size: 6rem;
  }
  .services__title--sections {
    line-height: 0.83;
    font-size: 14.4rem;
  }
  .services__sections {
    padding: 9rem 0;
  }
  .services__section {
    padding: 0;
  }
  .services__blocks {
    padding: 8.8rem 0;
    display: flex;
    flex-wrap: wrap;
  }
  .services__block {
    padding: 0;
    width: 33.33%;
    position: relative;
  }
  .services__block--1 {
    top: -16rem;
    padding-top: 16rem;
    margin-bottom: -16rem;
  }
  .services__block--2 {
    top: -8rem;
    padding-top: 8rem;
    margin-bottom: -8rem;
  }
  .services__service--sections-1 {
    padding: 0 0 0 42.6%;
  }
  .services__service--sections-2 {
    padding: 0 42.6% 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .about__block {
    position: static !important;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1100px) {
  html {
    font-size: calc(0.9vw + 0.1px);
  }
}

@media (hover: hover) {
  .btn:hover {
    background-color: #151515;
    color: #f9f6ee;
  }
  .hero__scrollto:hover {
    opacity: 0.7;
  }
  .footer__copyright:hover {
    opacity: 0.8;
  }
  .nav__item:hover {
    color: #fff;
  }
  .protected__submit:hover {
    background-color: #3176b4;
    background-color: var(--primaryColor);
    opacity: 0.9;
  }
}
