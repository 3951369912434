.success {
  padding: 2.4rem 0 0rem;
  margin-bottom: 3rem;
  @include min769 {
    padding: 0;
    margin-bottom: 6rem;
  }

  &__container {
    position: relative;
    z-index: 3;
  }

  &__top {
    &--index {
    }
  }

  &__title {
    color: #2a2b31;
    line-height: 1.1;
    font-size: 3.6rem;
    z-index: 2;
    @include min769 {
      position: absolute;
      line-height: 1.2;
      font-size: 6rem;
      top: 10.1rem;
      left: 0;
    }
  }

  &__items {
    padding: 3.5rem 0 1rem;
    @include min769 {
      padding: 6.3rem 0 8.7rem 34.2%;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 2.2rem 0 2.2rem 5.3rem;
    position: relative;
    @include min769 {
      width: 50%;
      padding: 3.7rem 2rem 3.7rem 0;

      &--1 {
        top: -6rem;
        padding-top: 9.7rem; // 6rem + 3.7rem
        margin-bottom: -6rem;
      }
    }
  }

  &__subtitle {
    line-height: 1.333;
    font-weight: 700;
    font-size: 1.8rem;
    padding-right: 7rem;
    @include min769 {
      line-height: 1.5;
      font-size: 2.4rem;
      max-width: 32rem;
      min-height: 7.2rem;
    }
  }

  &__description {
    color: #807e80;
    font-size: 1.4rem;
    line-height: 1.71;
    @include min769 {
      line-height: 1.67;
      font-size: 1.8rem;
      max-width: 32rem;
      // padding-right: 3rem;
    }

    p {
      margin: 1.4em 0;
    }
  }

  &__plane {
    z-index: 1;
    right: 12.2%;
    width: 87.8%;
    @include min769 {
      right: 51.2%;
      width: 48.8%;
    }
    // bottom: 0;
  }
}
