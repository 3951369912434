@font-face {
  font-family: 'Roboto Mono';
  // font-display: optional;
  // font-display: swap;
  font-display: fallback;
  src: url('../fonts/RobotoMono/RobotoMono-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto Mono';
  // font-display: optional;
  // font-display: swap;
  font-display: fallback;
  src: url('../fonts/RobotoMono/RobotoMono-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Mono';
  // font-display: optional;
  // font-display: swap;
  font-display: fallback;
  src: url('../fonts/RobotoMono/RobotoMono-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
