/* stylelint-disable */

.app {
  background-repeat: repeat-y;
  background-size: 100% auto;
  background-position: 0% 0%;

  &__background {
    @include absolute;
    pointer-events: none;
    overflow: hidden;
    z-index: -2;
  }

  &__parallax {
    @include absolute;
    pointer-events: none;
    background-image: url('../img/background.png');
    background-repeat: repeat-y;
    background-size: 100% auto;
    background-position: 0% 0%;
    will-change: transform;
    z-index: -2;
  }

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    height: 7.8rem;
  }

  &__logo {
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    padding: 2.7rem 1.9rem;
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
    z-index: 3;
    pointer-events: all;

    @include min769 {
      padding: 2.7rem 2.5rem;
    }
  }

  &__nav {
    position: fixed;
    top: 0;
    pointer-events: all;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    background-color: #000;
    // padding: 14.6rem 1rem;
    padding: 10vh 1rem;
    text-align: center;
    @include flex;
    display: none;

    @include min769 {
      padding: 0;
      text-align: left;
      overflow: visible;
      background-color: transparent;
      right: 1.4rem;
      left: auto;
      bottom: auto;
      display: block;
    }
  }

  &__navicon {
    z-index: 3;
    top: 0;
    right: 0;
    position: fixed;
    pointer-events: all;

    @include min769 {
      display: none;
    }
  }

  &__page {
    position: relative;
    z-index: 1;
    padding: 12.8rem 0 0;
    width: 100%;
    overflow-x: hidden;

    @include min769 {
      padding: 16.8rem 0 0;
    }
  }

  &__footer {
    padding: 2.3rem 0;
    text-align: right;

    @include min769 {
      text-align: left;
      padding: 2.8rem 0;
    }
  }

  &__observer {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  &__cookiebar {
    position: fixed;
    z-index: 2;
    display: none;
    pointer-events: all;

    .has-cookiebar & {
      display: block;
    }
  }
}
