.cookiebar {
  bottom: 3rem;
  right: 3rem;
  left: 3rem;
  background-color: #000;
  padding: 0.2rem 2.5rem 0.7rem;
  font-size: 1.4rem;
  color: #807e80;
  
  @include min769 {
    left: auto;
    font-size: 1.8rem;
    bottom: 5.3rem;
    right: 5.3rem;
    width: 53.6rem;
    padding: 0.2rem 2.5rem 0.7rem;
  }
  p {
    margin: 1em 0;
  }
  a {
    color: #fff;
  }

  &__close {
    width: 5.1rem;
    height: 5.1rem;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: -2.6rem;
    right: -2.6rem;
    cursor: pointer;
    @include min769 {
      width: 7.5rem;
      height: 7.5rem;
      top: -3.7rem;
      right: -3.7rem;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 1.5rem;
      height: 1px;
      background-color: #fff;
      top: 2.6rem;
      left: 1.8rem;
      @include min769 {
        width: 2.3rem;
        top: 3.7rem;
        left: 2.6rem;
      }
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
