.navicon {
  width: 5rem;
  height: 7rem;
  cursor: pointer;
  &__dot {
    position: absolute;
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    top: 3.5rem;
    right: 2.2rem;
    &--1 {
      transform: translate3d(0, -0.9rem, 0);
    }

    &--2 {
    }

    &--3 {
      transform: translate3d(0, 0.9rem, 0);
    }
  }
}
