h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  line-height: 1;
  font-weight: 700;
  font-family: 'Roboto Mono', Arial, Helvetica, sans-serif;
}

h1,
h2 {
  font-weight: 700;
  font-size: inherit;
  // font-size: 3.6rem;
}

p {
  margin: 1.8em 0;

  @include min769 {
    margin: 1.8em 0;
  }

  img {
    width: 100%;
  }
}
