@mixin max768() {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin min769() {
  @media only screen and (min-width: 769px) {
    @content;
  }
}

@mixin min1024() {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}

@mixin min1280() {
  @media only screen and (min-width: 1280px) {
    @content;
  }
}

@mixin hover() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
}
