$base: 10; // base font size in pixels
$break: 1100; // design size
$scale: 99; // viewport scale factor
$vw: (1000 / (100 / $scale) / $break) * 1vw;
$fsz: ((100 - $scale) / 100 * $base) * 1px;

html {
  font-size: $base * 1px;

  @media only screen and (min-width: 769px) and (max-width: 1100px) {
    font-size: calc(#{$vw} + #{$fsz});
  }

}
