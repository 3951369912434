.factors {
  &__title {
    color: #000;
    font-size: 6rem;
    line-height: 0.9;

    @include min769 {
      line-height: 0.83;
      font-size: 14.4rem;
    }
  }

  &__intro {
    position: relative;
    z-index: 2;
    color: #a6a6a6;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1.8;
    padding: 2.5rem 25% 1rem 0;

    @include min769 {
      padding: 2.5rem 0 1rem;
    }
  }

  &__blocks {
    margin: 5.5rem 0 0rem;

    @include min769 {
      display: flex;
      flex-wrap: wrap;
      margin: 8.8rem -1.5rem;
    }
  }

  &__block {
    margin-bottom: 1.7rem;

    @include min769 {
      margin-bottom: 0;
      padding: 1.5rem;

      width: 25%;
    }

    &--1,
    &--3 {
      @include min769 {
        margin-top: 19rem;
      }
    }
  }

  &__wrapper {
    position: relative;
    padding: 2rem 3.2rem 3.8rem 3.2rem;

    @include min769 {
      padding: 2rem 3.2rem 6.3rem 3.2rem;
    }
  }

  &__subtitle {
    position: relative;
    z-index: 2;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.55;
    padding-right: 29%;

    @include min769 {
      padding-right: 0;
      font-size: 2.4rem;
    }

    &:after {
      font-weight: inherit;
      content: '_';
      display: block;
      color: var(--primaryColor);
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    color: #807e80;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2;

    @include min769 {
    }

    p {
      margin: 1em 0;
    }
  }
  &__plane {
    left: 0;
    width: 100%;
  }
}
