.about {
  &__intro {
    color: #fff;
    font-size: 3.6rem;
    font-weight: 700;
    line-height: 1;

    @include min769 {
      font-size: 7.2rem;
    }
  }

  &__content {
    position: relative;
  }

  &__title {
    color: #000;
    font-size: 3.6rem;
    text-align: right;
    padding: 5.6rem 0 2.7rem;

    @include min769 {
      font-size: 6rem;
      position: absolute;
      top: 12.4rem;
      right: 0rem;
      padding: 0;
    }
  }

  &__blocks {
    margin: 0;

    @include min769 {
      display: flex;
      flex-wrap: wrap;
      margin: 4.8rem -1.5rem;
    }
  }

  &__block {
    margin-bottom: 1.5rem;

    @include min769 {
      margin-bottom: 0;
      padding: 1.5rem;
      width: 50%;
    }

    &--2 {
      @include min769 {
        margin-top: 28.4rem;
      }
    }

    @include max768 {
      position: static !important;
    }
  }

  &__wrapper {
    position: relative;
    padding: 2.6rem 3rem 2.9rem;

    @include min769 {
      padding: 5.4rem 12% 7.1rem;
    }
  }

  &__subtitle {
    position: relative;
    z-index: 2;
    font-size: 2.4rem;

    @include min769 {
      font-size: 3rem;
    }
  }

  &__text {
    position: relative;
    z-index: 2;
    color: #807e80;
    font-weight: 400;
    padding: 0.6rem 0;
    font-size: 1.2rem;
    line-height: 1.5;

    @include min769 {
      padding: 3.2rem 0;
      font-size: 1.4rem;
      line-height: 1.73;
    }

    p {
      margin: 1em 0;
    }
  }

  &__plane {
    width: 100%;
    &--1 {
      right: 0;
    }
    &--2 {
      left: 0;
    }
  }
}
