.nav {
  &__list {
    @include min769 {
      display: flex;
    }
  }

  &__trigger {
    padding: 1.7rem;
    font-size: 1.8rem;
    display: block;
    color: currentColor;
    @include min769 {
      font-size: 1.4rem;
      padding: 2.4rem 1.7rem;
    }
  }

  &__item {
    color: var(--greyLight);
    transition: color 200ms var(--e);

    &.is-active {
      color: #fff;
    }

    @include hover {
      color: #fff;
    }
  }
}
