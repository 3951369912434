.media {
  display: block;

  &__image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
