.protected {
  min-height: 100vh;
  @include flex;
  background-color: #000;
  text-align: center;

  &__icon {
    display: inline-block;
    width: 7.1rem;
    height: 7.1rem;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__intro {
    font-size: 1.2rem;

    @include min769 {
      font-size: 2.4rem;
    }

    p {
      margin: 1.1em 0;

      @include min769 {
        margin: 0.64em 0;
      }
    }
  }

  &__form {
    max-width: 36.6rem;
    display: block;
    margin: 4rem auto;

    @include min769 {
      margin: 4.2rem auto;
    }
  }

  &__input {
    display: block;
    width: 100%;
    font-size: 1.4rem;
    font-weight: 700;
    color: #fff;
    padding: 1.7rem 2.1rem;
    border: solid 1px #807e80;
    background-color: rgba(255, 255, 255, 0.05);

    @include min769 {
      padding: 1.5rem 2.1rem;
    }

    &:focus {
      border-color: #fff;
    }
  }

  &__error {
    text-align: right;
    color: #ff5050;
    font-size: 1.2rem;
    padding: 0.4rem 0;
    opacity: 0;

    .has-error & {
      opacity: 1;
    }

    @include min769 {
    }
  }

  &__submit {
    margin: 0.9rem 0;
    padding: 0.8rem 1rem 1rem;
    min-height: none;
    font-size: 1.4rem;
    letter-spacing: 0.4em;
    font-weight: 700;
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);

    @include min769 {
    }

    @include hover {
      background-color: var(--primaryColor);
      opacity: 0.9;
    }
  }
}
