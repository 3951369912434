.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  width: 100%;
  line-height: 1.14;
  min-height: 6rem;
  padding: 1rem 1.6rem;
  border: solid 0.3rem rgb(21, 21, 21);
  // font-weight: 700;
  text-align: center;
  transition: background-color 100ms var(--e);

  path,
  polygon {
    transition: fill 200ms var(--e);
    position: relative;
  }

  &__text {
    position: relative;
    color: currentColor;
    margin: 0 1rem;
    // transition: color 100ms var(--e);
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
    width: 2rem;
    margin: 0 1rem;
    text-align: center;
    color: currentColor;
    // transition: color 200ms var(--e), transform 200ms var(--e);
    svg {
      width: 100%;
      height: 100%;
      display: block;
      margin: auto;
    }
    path {
      fill: currentColor;
    }
  }

  @include hover {
    background-color: rgb(21, 21, 21);
    color: #f9f6ee;
  }

  &--download {
    justify-content: space-between;
  }
}
