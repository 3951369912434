html {
  background-color: var(--greyDark);
  color: #fff;
  font-size: 10px;
}

body {
  font-family: var(--primaryFont);
  color: inherit;
  position: relative;
  font-size: 1.2rem;
  line-height: 1.5;
  font-weight: 400;
  margin: 0;

  @include min769 {
    font-size: 1.8rem;
    line-height: 1.67;
  }

  ::-webkit-selection {
    background-color: var(--greyDark);
    color: #fff;
  }

  :-moz-selection {
    background-color: var(--greyDark);
    color: #fff;
  }

  ::selection {
    background-color: var(--greyDark);
    color: #fff;
  }

  ::placeholder {
    opacity: 1;
    transition: all 350ms var(--e);
  }
}

:focus::placeholder {
  opacity: 0.3;
}

a {
  text-decoration: none;
}

table {
  max-width: 100%;

  a {
    text-decoration: underline;
  }
}

tr {
  vertical-align: top;
}

td {
  text-align: inherit;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

li {
  margin: 0;
}
