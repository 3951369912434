.title {
  color: #000;
  line-height: 1;
  font-weight: 700;
  position: relative;
  display: block;

  &__line {
    display: block;

    @include min769 {
      padding-left: 28.7rem;
    }
    &--1 {
      @include min769 {
        padding-left: 0;
      }
      &:before {
        line-height: inherit;
        content: '_';
        color: var(--primaryColor);
      }
    }
    &--index {
      @include min769 {
        padding-left: 0;
      }
      &:before {
        display: none;
      }
    }
    &--success {
      @include min769 {
        padding-left: 0;
      }
    }
    &--stroke {
    }
  }
  &__fill {
    @include absolute;
    bottom: auto;
    overflow: hidden;
    height: 90%;
  }
  &__letter {
    display: inline-block;
  }
}
