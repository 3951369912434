.footer {
  &__content {
    position: relative;
  }

  &__contacts {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.25;
    margin: 1.3rem 0;

    @include min769 {
      margin: 0;
      padding: 0 20% 0 0;
      font-size: 7.2rem;
    }
  }

  &__contact {
    display: inline-block;
  }

  &__title {
    font-size: 3.6rem;
    font-weight: 700;

    @include min769 {
      font-size: 6rem;
      // top: 0.8rem;
      top: 2.2rem;
      right: -0.6rem;
      position: absolute;
    }
  }

  &__bottom {
    padding: 5.8rem 0 1rem;

    @include min769 {
      padding: 7rem 0 1rem;
    }
  }

  &__copyright {
    text-align: right;
    font-size: 1rem;
    display: block;
    // color: #807e80;
    color: #fff;
    transition: opacity 0.5s var(--e);
    opacity: 0.5;
    @include hover {
      opacity: 0.8;
    }

    @include min769 {
      font-size: 1.2rem;
    }
  }
}
