.default {
  padding: 2.3rem 0 1.1rem;

  @include min769 {
    padding: 1.8rem 0;
  }

  &__title {
    font-size: 3rem;

    @include min769 {
      font-size: 6rem;
    }
  }

  &__content {
    color: var(--greyLight);
    max-width: 82rem;
    padding: 3.4rem 0;

    @include min769 {
      padding: 5.1rem 2rem;
    }

    ul,
    ol {
      padding: 0 0 0 5.6rem;

      @include min769 {
        padding: 0 0 0 11.2rem;
      }

      li {
        list-style-type: none;

        &:after {
          content: '';
          position: absolute;
          top: 0.5rem;
          left: -1.8rem;
          width: 0.9rem;
          height: 0.9rem;
          background-color: var(--primaryColor);

          @include min769 {
            top: 1.1rem;
            left: -2.8rem;
          }
        }
      }
    }

    ol {
      counter-reset: section;

      li {
        text-indent: 2.3rem;
        position: relative;

        @include min769 {
          text-indent: 3.3rem;
        }

        &:before {
          counter-increment: section;
          content: counter(section) '.';
          position: absolute;
          top: 0;
          left: -2.2rem;

          @include min769 {
            left: -3.2rem;
          }
        }
      }
    }
  }
}
